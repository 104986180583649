.Support {
  &__body {
    margin: auto;
    padding: 25px;
    padding-top: 100px;
    margin-top: 100px;
    max-width: 1300px;
    min-height: 500px;
    color: $brand-cool-blue;

    &__strip {
      display: flex;

      &__gradient {
        width: 100%;
        background: linear-gradient(
          38.33deg,
          #030e2ddb 27.51%,
          #163049db 65.75%,
          #264d60db 105.56%
        );
        min-height: 200px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        text-align: center;
        max-width: 450px;
        line-height: 2.2rem;
        padding: 50px;
        padding-top: 100px;
        color: #fff;

        & a {
          color: #fff;
        }
      }

      &__img {
        width: 100%;

        & img {
          max-width: 100%;
        }
      }
    }

    &__contact {
      background-color: $brand-color-light;
      min-height: 300px;
      border-radius: 20px;
      padding: 30px;
      padding-top: 60px;
      margin-top: 50px;

      & > h3 {
        text-align: center;
        max-width: 700px;
        margin: auto;
        line-height: 2.2rem;
      }

      &__block {
        background-color: #fff;
        margin-right: 20px;
        margin-bottom: 20px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      &__card {
        padding: 50px;
        display: flex;

        &--third {
          margin-bottom: 209px;
        }

        &__icon {
          margin-right: 30px;

          & svg {
            max-width: 100px;
          }
        }

        &__details {
          & > h5 {
            font-weight: bolder;
            margin-bottom: 15px;
          }

          & > p {
            line-height: 2.2rem;
          }

          & button {
            padding: 12px 15px;
            margin: -15px 0;
            margin-right: 10px;
            text-transform: uppercase;
            font-size: smaller;

            & a {
              color: #000;
              text-decoration: none !important;
            }

            & svg {
              max-width: 25px;
              margin: -12px 0;
              margin-left: 20px;
            }
          }
        }
      }
    }

    &__faq {
      margin: 50px 0;
      margin-top: 150px;

      & > h2 {
        font-weight: bolder;
        text-align: center;
      }

      &__item {
        margin: 30px 0;
        transition: all 1.2s ease-in-out;

        &--open {
          background-color: #fff;
          filter: drop-shadow(0px 25px 30px #5c7ea027);
          border-radius: 20px;
          padding: 25px;

          & > .Support__body__faq__item__header {
            display: flex;
            justify-content: space-between;

            & > span {
              margin-top: 20px;
              color: $brand-color-dark;
            }

            & > svg {
              max-width: 35px;
            }
          }

          & > .Support__body__faq__item__body {
            line-height: 2.2rem;
            margin-top: 20px;
          }
        }

        &--closed {
          background-color: $brand-color-light;
          border: 1px solid #c5cbceab;
          border-radius: 20px;
          padding: 10px 25px;

          & > .Support__body__faq__item__header {
            display: flex;
            justify-content: space-between;

            & > span {
              margin-top: 20px;
            }

            & > svg {
              max-width: 35px;
            }
          }

          & > .Support__body__faq__item__body {
            display: none;
          }
        }

        &__header {
          cursor: pointer;

          & > svg {
            margin-left: 20px;
          }
        }
      }

      &__more {
        text-align: center;

        & button {
          padding: 5px;
          min-width: 199px;

          & svg {
            max-width: 50px;
            margin: -20px 0;
          }
        }
      }
    }

    &__policy {
      margin: 50px 0;
      margin-top: 150px;

      & > h2 {
        font-weight: bolder;
        text-align: center;
      }

      & p {
        line-height: 2.2rem;
      }
    }
  }
}

@media (max-width: $breakpoint-bs-xl) {
  .Support {
    &__body {
      &__strip {
        &__gradient {
          border-radius: 20px;
          padding-top: 50px;
          max-width: 100%;

          & p {
            max-width: 600px;
            text-align: center;
            margin: auto;
          }
        }

        &__img {
          display: none;
        }
      }

      &__contact {
        &__block {
          margin-right: 0;
        }

        &__card {
          &--third {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-bs-md) {
  .Support {
    &__body {
      &__contact {
        &__block {
          margin-right: 0px;
        }

        &__card {
          flex-wrap: wrap;

          &__icon {
            & svg {
              max-width: 100%;
              margin-bottom: 30px;
            }
          }

          &__details {
            & button {
              margin: 15px 0;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-bs-sm) {
  .Support {
    &__body {
      &__contact {
        padding: 10px;
        padding-top: 50px;

        &__card {
          text-align: center;

          &__icon {
            margin: auto;
          }

          &__details {
            &__btn__block {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
