.About {
  &__body {
    min-height: 500px;
    padding-top: 80px;
    color: $brand-cool-blue;

    &__info {
      display: flex;
      justify-content: space-between;
      max-width: 1300px;
      margin: auto;
      padding: 25px;
    }

    &__content {
      margin-top: 60px;

      & > h5 {
        font-weight: bolder;
      }

      & > h3 {
        font-weight: bolder;
      }

      & > p {
        line-height: 2.2rem;
        margin-top: 40px;
        max-width: 550px;
      }
    }

    &__image {
      margin-top: 20px;
      max-width: 100%;

      & > .About__body__image__item {
        min-height: 250px;
      }

      &__batch {
        display: flex;
      }

      &__item {
        background-color: #c8c8c8;
        min-height: 150px;
        min-width: 150px;
        border-radius: 40px;
        padding: 70px;
        margin: 10px 15px;
      }
    }

    &__video {
      margin-top: 20px;
      max-width: 100%;
      padding: 20px;

      & iframe {
        width: 500px;
        height: 100%;
        border-radius: 10px;
      }

      & img {
        width: 500px;
        max-width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }

    &__customers {
      background-color: $brand-color-light;
      min-height: 300px;
      margin: 50px 0;
      margin-bottom: -20px;
      padding-bottom: 50px;

      &__inner {
        max-width: 1300px;
        margin: auto;
        overflow: hidden;
        position: relative;
      }

      &__title {
        text-align: center;
        margin: auto;
        padding: 25px;
        padding-top: 80px;
        font-weight: bolder;
      }

      &__items {
        display: inline-flex;
        position: relative;
        max-width: 100%;
        list-style: none;
        transition: all 0.3s ease-in-out;

        &__overlay {
          background: linear-gradient(
            90.15deg,
            #f4faffe6 0.13%,
            #ffffff82 16.08%,
            #f4faff00 48.5%,
            #ffffff82 81.94%,
            #f4faffe6 98.92%
          );
          min-height: 380px;
          position: absolute;
          z-index: 10;
          left: 0;
          right: 0;
        }
      }

      &__item {
        min-height: 200px;
        filter: drop-shadow(0px 10px 8px #5c7ea027);
        border-radius: 25px;
        padding: 25px;
        background-color: #fff;
        margin-right: 30px;
        margin-bottom: 30px;
        max-width: 600px;
        text-align: center;
        min-width: 33.3%;

        & > p {
          line-height: 2.2rem;
          height: 200px;
          overflow-y: auto;
        }

        & > h5 {
          font-weight: bolder;
        }
      }

      &__controls {
        margin: auto;
        margin-top: 50px;
        max-width: 350px;
        text-align: center;

        &__inner {
          display: flex;

          & svg {
            cursor: pointer;
          }
        }

        &__group {
          display: flex;
        }

        &__item {
          border-radius: 100%;
          padding: 10px;
          margin-right: 20px;
          margin-top: 50px;
          max-width: 40px;
          max-height: 20px;
          background-color: #c8c8c8;

          &--active {
            background-color: $brand-color-dark !important;
          }

          &:last-of-type {
            margin-right: 0px;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-bs-md) {
  .About {
    &__body {
      &__info {
        flex-wrap: wrap;
      }

      &__content {
        order: 2;
        margin: auto;
      }

      &__image {
        order: 1;
        margin: auto;
        margin-bottom: 40px;
      }

      &__video {
        order: 1;
        margin: auto;
        margin-bottom: 40px;
        padding: 0 !important;

        & iframe {
          height: 400px;
          width: 550px;
          max-width: 100%;
          max-height: 100%;
        }
      }

      &__customers {
        &__items {
          &__overlay {
            background: none;
            display: none;
          }
        }

        &__item {
          min-width: 100%;

          & > p {
            height: auto;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-tiny-screens) {
  .About {
    &__body {
      &__image {
        &__item {
          min-width: auto;
          min-height: auto;
          padding: 25px;
        }
      }
    }
  }
}
