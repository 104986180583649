@font-face {
    font-family: 'Avenir';
    src: url(../../assets/fonts/Avenir/avenir-condensedhand.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Avenir';
    src: url(../../assets/fonts/Avenir/avenir-light.otf) format('opentype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir';
    src: url(../../assets/fonts/Avenir/avenir-book.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir';
    src: url(../../assets/fonts/Avenir/avenir-roman.otf) format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir';
    src: url(../../assets/fonts/Avenir/avenir-medium.otf) format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Avenir';
    src: url(../../assets/fonts/Avenir/avenir-black.otf) format('opentype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }