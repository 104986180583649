// FONT FAMILY **/
$font-family-base: "Avenir", "Helvetica Neue", "Roboto", sans-serif;

// BREAKPOINTS **/
$breakpoint-tiny-screens: 375px;
$breakpoint-small-screens: 450px;
$breakpoint-tablet: 600px;
$breakpoint-desktop: 1000px;
$breakpoint-bs-sm: 576px;
$breakpoint-bs-md: 768px;
$breakpoint-bs-lg: 992px;
$breakpoint-bs-xl: 1200px;
$breakpoint-header: 850px;

// COLORS **/
$brand-color: #60C0ED;
$brand-color-dark: #2294E3;
$brand-color-darker: #03182d;
$brand-color-light: #F4FAFF;
$brand-cool-blue: #264D60;
$brand-color-card: #499FF7;
$brand-orange: #ffc533;
$brand-text-color: #5C5C5C;
$brand-input-placeholder: #5c5c5c9c;
$brand-input-disabled: #c4c4c446;
$brand-input-border: #839AA8;
$brand-lemon: #9dcb3b;
$brand-lemon-lighter: #E7F5E2;
$color-success: #41b661;
$color-danger: #b80202;

$grey: #4a4a4a;
$grey-light: #AAA9A9;
$grey-medium: #4a4a4a80;
