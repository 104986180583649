.Footer {
  margin-top: 20px;
  font-size: .9rem;

  &__bg {
    background: linear-gradient(
      38.33deg,
      #030e2ddb 27.51%,
      #163049db 65.75%,
      #264d60db 105.56%
    );
    min-height: 500px;
  }

  &__content {
    margin: auto;
    max-width: 1300px;
    padding: 40px 20px;
    padding-bottom: 50px;
    color: #fff;

    & a {
      color: #fff;
      text-decoration: none;
    }
  }

  &__subscribe {
    padding-top: 50px;

    & h1 {
      font-weight: bolder;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      line-height: 2.2rem;

      &__text {
        max-width: 520px;
      }

      &__form {
        width: 50%;
        margin-top: -50px;

        &__button {
          text-align: right;
          margin-top: -86px;
          margin-right: 4px;

          & button {
            min-width: 240px;
            padding: 12px !important;

            & svg {
              max-width: 30px;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }

  &__divider {
    margin-top: 60px;
    margin-bottom: 100px;
    border-top: 0.8px solid #fff;
  }

  &__main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__content {
      margin-bottom: 35px;

      & > img {
        max-width: 180px;
        margin-left: -20px;
        margin-bottom: 30px;
      }

      & > p {
        max-width: 480px;
        line-height: 2.2rem;
      }

      &__socials {
        margin-top: 30px;

        & a {
          margin-right: 25px;

          & > img {
            max-width: 20px;
          }
        }
      }
    }

    &__links {
      margin-bottom: 20px;

      & h2 {
        font-weight: bolder;
      }

      & a {
        display: block;
        margin-bottom: 20px;
      }
    }

    &__contact {
      margin-bottom: 20px;

      & h2 {
        font-weight: bolder;
      }

      & span {
        display: block;
        margin-bottom: 20px;
      }
    }
  }

  &__copy {
    margin-top: 50px;
    line-height: 2rem;

    & img {
      max-width: 20px;
    }
  }
}

@media (max-width: $breakpoint-desktop) {
  .Footer {
    &__subscribe {
      text-align: center;

      &__content {
        flex-wrap: wrap;

        &__form {
          margin-top: 10px !important;
        }

        &__text {
          margin: auto;
          margin-bottom: 30px;
        }

        &__form {
          width: 100% !important;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-small-screens) {
  .Footer {
    &__subscribe {
      &__content {
        &__form {
          &__button {
            margin-top: -20px;

            & button {
              min-width: 200px !important;

              & svg {
                max-width: 25px !important;
                margin-left: 5px !important;
              }
            }
          }
        }
      }
    }
  }
}
