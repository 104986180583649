.Header {
  &__bg {
    min-height: 500px;
    max-width: 100%;
    position: relative;
    background: linear-gradient(
      38.33deg,
      #030e2ddb 27.51%,
      #163049db 65.75%,
      #264d60db 105.56%
    );
    &__text {
      min-height: 500px;
      max-width: 100%;
      overflow: auto;
      text-transform: capitalize;
      font-size: 17.5vw;
      font-weight: bolder;
      z-index: 1;
      color: #ffffff0d;
      position: absolute;
      bottom: 0px;
      left: -10px;
    }
    &__img {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: -1;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__top {
    padding: 0 12px;
    padding-top: 15px;

    &--dark {
      background-color: $brand-color-darker;
    }
  }

  &__menu {
    max-width: 1300px;
    margin: auto;
    padding: 0 35px;

    &__inner {
      margin: auto;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__icon {
      margin-top: 13px;
      display: none;
    }

    &__logo {
      max-width: 150px;

      & img {
        max-width: 100%;
      }
    }

    &__links {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
    }

    &__link {
      color: #fff;
      text-decoration: none;
      margin-right: 50px;
      font-weight: 610;
      font-size: medium;

      &:hover {
        text-decoration: none;
        color: #fff;
      }

      & i {
        color: $brand-color-dark;
        margin-left: 8px;
      }

      &--active {
        color: $brand-color-dark !important;
      }
    }

    &__support {
      &:hover {
        & .Header__menu__support__subMenu {
          display: block !important;
        }
      }

      &__link {
        color: #000;
        font-size: small;
        font-weight: bold;
        text-decoration: none;
        display: block;
        margin-bottom: 10px;

        &:hover {
          text-decoration: none;
        }

        &--active {
          color: $brand-color-dark !important;
        }
      }

      &__subMenu {
        border-radius: 30px;
        border-top-right-radius: 0px;
        padding: 30px 20px;
        max-width: 300px;
        background-color: #fff;
        position: absolute;
        z-index: 100;
        margin-top: 15px;
        margin-left: -100px;
        box-shadow: 0 0 2px 1px #264d6033;
        transition: all 0.5s ease-in-out;
        display: none;
      }
    }
  }

  &__smallMenu {
    transition: all 1.9s ease-in-out;
    background-color: $brand-color-darker;
    padding-top: 20px;
    margin-bottom: 20px;
    min-height: 200px;
    position: absolute;
    z-index: 100;
    width: 100%;

    &__link {
      display: block;
      border-bottom: 0.8px solid #ffffff59;
      padding: 9px 15px;
      color: #fff;

      &--active {
        color: $brand-color-dark;
      }
    }
  }

  &__body {
    color: #fff;
    max-width: 1300px;
    margin: auto;
    padding: 0 30px;

    &__content {
      margin-top: 100px;
      padding: 0 20px;

      & h1 {
        font-weight: bolder;
        font-size: 6vh;
      }

      & p {
        font-weight: 500;
        font-size: 2.5vh;
        max-width: 700px;
        line-height: 2rem;
      }
    }
  }

  &__apple {
    background-color: #091820;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 2px solid #fff;
    border-top: none;
    margin-top: -15px;
    padding: 20px;
    display: flex;
    justify-content: space-between;

    &__logo {
      max-width: 50px;
      margin-right: 15px;

      & img {
        max-width: 100%;
      }
    }

    &__text {
      color: #fff;
      font-size: small;
    }
  }

  &__bottom {
    background-color: $brand-color-darker;
    padding: 20px;
    z-index: 56;
    position: absolute;
    left: 0;
    right: 0;
    margin-top: -30px;
    margin-bottom: 100px;
    min-height: 100px;

    &__inner {
      margin: auto;
      max-width: 1000px;
      text-align: center;

      &--remove {
        display: inline;
      }
    }

    & img {
      max-width: 60px;
      margin-right: 50px;
    }
  }
}

@media (min-width: 1600px) {
  .Header {
    &__bg {
      &__text {
        bottom: -175px !important;
      }
    }
  }
}

@media (min-width: 1250px) and (max-width: 1600px) {
  .Header {
    &__bg {
      &__text {
        bottom: -225px !important;
      }
    }
  }
}

@media (min-width: $breakpoint-bs-lg) and (max-width: 1250px) {
  .Header {
    &__bg {
      &__text {
        bottom: -275px !important;
      }
    }
  }
}

@media (min-width: $breakpoint-bs-md) and (max-width: $breakpoint-bs-lg) {
  .Header {
    &__bg {
      &__text {
        bottom: -310px !important;
      }
    }
  }
}

@media (min-width: $breakpoint-bs-sm) and (max-width: $breakpoint-bs-md) {
  .Header {
    &__bg {
      &__text {
        bottom: -340px !important;
      }
    }
  }
}

@media (min-width: $breakpoint-tiny-screens) and (max-width: $breakpoint-bs-sm) {
  .Header {
    &__bg {
      &__text {
        bottom: -380px !important;
      }
    }
  }
}

@media (max-width: $breakpoint-tiny-screens) {
  .Header {
    &__bg {
      &__text {
        bottom: -400px !important;
      }
    }
  }
}

@media (max-width: $breakpoint-bs-lg) {
  .Header {
    &__menu {
      &__icon {
        display: block !important;
        margin-top: 22px !important;
      }

      &__links {
        display: none;
      }
    }

    &__bottom {
      &__inner {
        &--remove {
          display: none;
        }
      }

      & img {
        margin: 15px 20px;
      }
    }

    &__body {
      &__content {
        padding: 10px !important;
      }
    }
  }
}

@media (max-width: $breakpoint-small-screens) {
  .Header {
    &__menu {
      &__icon {
        margin-top: 13px !important;

        & svg {
          max-width: 22px;
        }
      }

      &__logo {
        max-width: 150px !important;
      }
    }

    &__bottom {
      & img {
        &:last-of-type {
          display: none;
        }
      }
    }

    &__apple {
      padding: 10px;

      &__logo {
        max-width: 30px;
        margin-right: 15px;

        & img {
          margin-top: 10px;
        }
      }

      &__text {
        font-size: 1.5vh;
      }
    }
  }
}
