.Partnership {
  &__body {
    margin: auto;
    padding: 25px;
    padding-top: 100px;
    margin-top: 100px;
    max-width: 950px;
    min-height: 500px;
    color: $brand-cool-blue;

    & > h5 {
      margin-bottom: 0;
      line-height: 1;
      font-weight: bolder;
    }

    & > p {
      max-width: 870px;
      line-height: 2.1rem;
    }

    &__benefits {
      margin-bottom: 50px;

      &__items {
        margin-left: 50px;
      }

      &__item {
        display: flex;

        & > svg {
          width: 150px;
          margin-right: 30px;
        }

        &__content {
          margin-top: 50px;

          & h5 {
            font-weight: bolder;
            text-transform: capitalize;
          }

          & p {
            line-height: 2.2rem;
            max-width: 500px;
          }
        }
      }
    }

    &__tabs {
      margin-left: -100px;
      margin-right: -100px;
      transition: all 1.2s ease-in-out;

      &__header {
        display: flex;
        width: 100%;

        &__item {
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          padding: 35px;
          width: 50%;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;
          font-weight: bold;
          background-color: #f4f4f4;
          color: #717171;

          &--active {
            background-color: $brand-color-dark;
            color: #fff;
          }
        }
      }

      &__body {
        min-height: 300px;
        padding: 50px;
        border: 1px solid #d3d3d3;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;

        &__content {
          & > p {
            line-height: 2.2rem;
          }

          & ul > li {
            line-height: 2.2rem;
          }
        }
      }
    }

    &__reach {
      margin-top: 40px;

      & > h3 {
        font-weight: bolder;
        text-align: center;
        margin: 30px 0;
        margin-top: 100px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .Partnership {
    &__body {
      &__tabs {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media (max-width: $breakpoint-bs-md) {
  .Partnership {
    &__body {
      &__benefits {
        &__items {
          margin-left: 0px !important;
        }

        &__item {
          & > svg {
            width: 100px;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-small-screens) {
  .Partnership {
    &__body {
      &__benefits {
        &__item {
          & > svg {
            max-width: 100%;
            width: auto;
          }
        }
      }

      &__tabs {
        &__header {
          flex-wrap: wrap;

          &__item {
            width: 100%;

            &:last-of-type {
              border-radius: 0px;
            }
          }
        }

        &__body {
          padding: 25px;
        }
      }
    }
  }
}
