.Home {
  &__header {
    display: flex;
    justify-content: space-between;

    &__content {
      margin-top: 100px;
      padding: 0 20px;

      & h1 {
        font-weight: bolder;
        font-size: 6vh;
      }

      & p {
        font-weight: 500;
        font-size: 2.5vh;
        max-width: 600px;
        line-height: 2rem;
      }

      & button {
        padding: 0px 25px;

        & a {
          color: #000;
          text-decoration: none !important;
        }

        & svg {
          max-width: 25px;
          margin-left: 15px;
        }
      }
    }

    &__iphone {
      margin-top: 40px;
      margin-bottom: -200px;

      & img {
        max-width: 700px;
      }
    }

    &__logos {
      display: flex;
      margin-top: 100px;
      margin-bottom: 70px;

      & img {
        max-width: 40px;
        max-height: 40px;
        margin-right: 20px;
      }

      &--remove {
        display: inline-flex;
      }
    }
  }

  &__body {
    min-height: 500px;
    color: $brand-cool-blue;

    &__whyus {
      margin: auto;
      padding: 25px;
      padding-top: 100px;
      margin-top: 100px;
      margin-bottom: 30px;
      max-width: 1300px;

      &__desc {
        margin-top: -50px;

        & > h5 {
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 15px;
        }

        & > p {
          line-height: 2.2rem;
          font-weight: bolder;
          font-size: 22px;
          max-width: 250px;
        }

        &__divider {
          border-radius: 20px;
          max-width: 80px;
          min-height: 2px;
          margin-bottom: 20px;
          border: 2px solid $brand-color-dark;
        }
      }

      &__item {
        margin-bottom: 30px;

        & > svg {
          max-width: 80px;
        }

        & > h5 {
          font-weight: bolder;
          text-transform: capitalize;
        }

        & > p {
          line-height: 2rem;
          max-width: 300px;
        }
      }
    }

    &__services {
      background-color: #3399ff18;
      min-height: 300px;

      &__inner {
        margin: auto;
        padding: 25px;
        padding-top: 150px;
        max-width: 1300px;

        & > h5 {
          text-align: center;
        }

        & > h2 {
          font-weight: bolder;
          text-align: center;
        }

        & > p {
          line-height: 2rem;
          max-width: 700px;
          text-align: center;
          margin: auto;
        }
      }

      &__items {
        margin-top: 80px;
        margin-bottom: 40px;
      }

      &__item {
        background-color: #fff;
        border-radius: 20px;
        padding: 20px;
        width: 180px;
        margin: auto;
        margin-bottom: 30px;
        border: 5px solid transparent;
        transition: all 0.7s ease-in-out;

        &:hover {
          box-shadow: 0px 0px 15px 1px #00000047;
        }

        & > a {
          color: $brand-cool-blue;
          text-decoration: none;

          & > svg {
            max-width: 50px;
          }

          & > h6 {
            font-weight: 610;
            margin-bottom: 0px;
          }

          & > span {
            font-size: 16px;
          }
        }

        &--purple {
          &:hover {
            border: 5px solid #c7c5f5;
          }
        }

        &--red {
          &:hover {
            border: 5px solid #f5c5c5;
          }
        }

        &--yellow {
          &:hover {
            border: 5px solid#FFE07B;
          }
        }

        &--green {
          &:hover {
            border: 5px solid#8EF4E8;
          }
        }

        &--neon-purple {
          &:hover {
            border: 5px solid#DEC9E3;
          }
        }

        &--blue {
          &:hover {
            border: 5px solid#96D0FF;
          }
        }
      }
    }

    &__svg-desc {
      margin: auto;
      padding: 25px;
      margin-top: 70px;
      margin-bottom: 30px;
      max-width: 1300px;

      & img {
        max-width: 100%;
      }
    }

    &__business {
      margin: auto;
      margin-top: 100px;
      padding: 25px;
      max-width: 1300px;

      & > h3 {
        font-weight: bolder;
        line-height: 2.5rem;
        text-align: center;
        margin-bottom: 25px;
      }

      & > p {
        line-height: 2.2rem;
        text-align: center;
        max-width: 1100px;
        margin: auto;
      }

      &__btn {
        margin-top: 30px;
        text-align: center;

        & button {
          padding: 0px 25px;
          padding-top: 3px;

          & svg {
            max-width: 25px;
            margin-left: 15px;
          }
        }
      }

      &__bar {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-top: 80px;
        padding: 50px;
        background-color: #fff;
        border-radius: 20px;
        filter: drop-shadow(0px 15px 69px #5c7ea027);
      }

      &__item {
        max-width: 180px;
        margin: 0 auto;

        &:nth-of-type(3) {
          margin-top: 30px;
        }

        &:nth-of-type(4) {
          margin-top: 10px;
        }

        & img {
          max-width: 100%;
          margin: 10px 10px;
        }
      }
    }

    &__faq {
      background-color: #3399ff18;
      min-height: 300px;
      margin-top: 150px;

      &__inner {
        margin: auto;
        padding: 0 25px;
        padding-top: 80px;
        max-width: 1300px;

        & > h3 {
          font-weight: bolder;
          text-align: center;
          line-height: 2.5rem;
        }
      }

      &__row {
        display: flex;
        justify-content: space-between;
        margin-top: 100px;
      }

      &__phone {
        z-index: -1;

        & img {
          max-width: 600px;
          margin-right: 50px;
        }
      }

      &__items {
        margin: 0 auto;
        margin-bottom: 100px;
        flex: auto;
        align-self: center;
      }
    }

    &__metrics {
      background-image: url(../../assets/images/city-img.jpg);
      background-size: cover;
      min-height: 250px;
      margin-top: -100px;
      margin-bottom: -20px;

      &__inner {
        margin: auto;
        padding: 80px 25px;
        max-width: 1300px;
        display: flex;
        justify-content: space-between;
      }

      &__divider {
        width: 2px;
        min-height: 100px;
        margin: auto 20px;
        background-color: #fff;
        border-radius: 20px;
      }

      &__item {
        color: #fff;
        margin: 10px 10px;

        &:nth-of-type(1) {
          margin-top: 45px;
        }

        &:nth-of-type(3) {
          & > h1 {
            margin-left: -6px;
          }
        }

        // &:nth-of-type(7) {
        //   margin-top: 45px;

        //   & > h1 {
        //     margin-left: -8px;
        //   }
        // }

        & > small {
          font-size: 3vh;
        }

        & > h1 {
          font-size: 6.5vh;
          font-weight: bold;
          line-height: 3.2rem;
        }

        & > h5 {
          font-size: 2.5vh;
          font-weight: normal;
          margin-top: -9px;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-bs-xl) {
  .Home {
    &__header {
      &__iphone {
        display: none;
      }
    }

    &__body {
      &__metrics {
        &__inner {
          flex-wrap: wrap;
        }

        &__divider {
          display: none;
        }

        &__item {
          margin-bottom: 60px;
          width: 45%;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-bs-lg) {
  .Home {
    &__header {
      &__content {
        padding: 10px !important;
      }
    }

    &__body {
      &__services {
        &__item {
          width: 100%;
          text-align: center;
        }
      }

      &__faq {
        &__row {
          margin-top: 40px;
        }

        &__phone {
          display: none;
        }
      }

      &__metrics {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: $breakpoint-bs-md) {
  .Home {
    &__body {
      &__whyus {
        &__desc {
          text-align: center;

          & > p {
            max-width: 100%;
          }

          &__divider {
            margin: auto;
            margin-bottom: 20px;
          }
        }

        &__item {
          margin: auto;
          margin-bottom: 30px;
          text-align: center;

          & > svg {
            margin: auto;
          }

          & > p {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-bs-sm) {
  .Home {
    &__header {
      &__logos {
        max-width: 100%;

        &--remove {
          display: none;
        }
      }
    }
  }
}
