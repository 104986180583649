.Repair {
  &__body {
    margin: auto;
    padding: 25px;
    margin-top: 100px;
    padding-top: 150px;
    max-width: 1300px;
    min-height: 500px;
    color: $brand-cool-blue;

    & > h2 {
      font-weight: bolder;
      text-align: center;
    }

    & > h4 {
      text-align: center;
      margin: 25px 0;
    }

    &__item {
      border: 1px solid $brand-cool-blue;
      padding: 25px;
      margin: 25px;
      border-radius: 8px;
      text-align: center;
      font-weight: 600;
      text-transform: capitalize;
      cursor: pointer;
    }

    &__more {
      text-align: center;

      & button {
        padding: 8px;

        & svg {
          max-width: 50px;
          margin: -20px 0;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-small-screens) {
  & .Header__body__content {
    & h1 {
      font-size: 5.5vh;
    }
  }
}

.iframe-repair {
  margin: 14rem 0 3rem 8rem;
  height: 165rem !important;
  overflow: hidden;
 
    iframe{
      border:none;
      width: 100%;
      height: 100%;
      display: block;
    }
}

@media (max-width: $breakpoint-small-screens) {
  & .iframe-repair{
    margin: 10rem 0.5rem 0 0;
  }
}