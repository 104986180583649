.Button {
  box-sizing: border-box;
  border-radius: 9px;
  padding: 13px;
  text-decoration: none;
  min-width: 20%;
  transition: all 0.6s ease-in-out;
  font-weight: 300;

  &:focus {
    outline: none;
  }

  &--invisible {
    visibility: hidden;
  }

  &--visible {
    visibility: visible;
  }

  &--brand {
    background-color: #33A3FF;
    border: none;
    border-radius: 40px;
    padding: 15px;
    font-weight: bolder;
    font-size: 20px;
    color: #000;

    &:hover {
      // transform: scale(1.1);
      background-color: #2483d1;
    }

    &-static {
      background: linear-gradient(
        102.31deg,
        #67c0ea 0%,
        #3ca4f0 63.9%,
        #1978db 95.78%
      );
      color: #fff !important;
      border: none !important;
    }

    &-white {
      background-color: transparent;
      border: 2px solid #fff;
      color: #fff !important;

      &:hover {
        background-color: #fff;
        color: $brand-color-darker !important;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}
