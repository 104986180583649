.Track {
  &__header {
    margin: 30px auto;
    text-align: center;

    &__process {
      display: flex;
      justify-content: space-between;
      margin: auto;
      max-width: 1000px;

      & svg {
        margin-top: 105px;
      }

      &__item {
        &--active {
          & .Track__header__process__item {
            &__box {
              background-color: $brand-color !important;
            }

            &__circle {
              background-color: #fff !important;
            }

            &__text {
              color: #fff !important;
            }
          }
        }

        &__box {
          min-height: 50px;
          width: 50px;
          margin: auto;
          padding: 35px;
          border-radius: 20px;
          background-color: #c8c8c8;
        }

        &__circle {
          background-color: #c8c8c8;
          border-radius: 100%;
          min-height: 35px;
          width: 35px;
          padding: 10px;
          margin: auto;

          &--inner {
            background-color: #26304b;
            border-radius: 100%;
            min-height: 15px;
            width: 15px;
            padding: 7px;
            margin: auto;
          }
        }

        &__text {
          text-transform: uppercase;
          font-weight: bolder;
          color: #c8c8c8;
          font-size: 12px;
          line-height: 1.4rem;
        }
      }
    }
  }

  &__body {
    min-height: 500px;
    margin: auto;
    max-width: 1500px;
    padding: 25px;
    margin-top: 100px;
    padding-top: 140px;

    & > h2 {
      font-weight: bolder;
      color: $brand-cool-blue;
      text-align: center;
    }

    & > p {
      line-height: 1.8rem;
      font-size: 19px;
      color: $brand-cool-blue;
      text-align: center;
    }

    &__card {
      margin: 60px auto;
      background-color: #fff;
      filter: drop-shadow(0px 25px 99px #00000017);
      min-height: 300px;
      border-radius: 20px;
      max-width: 850px;
      padding: 35px;
      color: #5C5C5C;

      &__button {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 15px;

        & button {
          min-width: 270px;
          padding: 8px;

          & svg {
            margin-left: 25px;
            max-width: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-bs-lg) {
  .Track {
    &__header {
      &__process {
        &__item {
          &__text {
            & br {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-bs-md) {
  .Track {
    &__header {
      &__process {
        &__item {
          &:nth-of-type(2) {
            display: none;
          }
        }

        & svg.TrackArrow {
          &:nth-of-type(2) {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: $breakpoint-small-screens) {
  .Track {
    &__header {
      &__process {
        &__item {
          &:nth-of-type(3) {
            display: none;
          }

          &:last-of-type {
            & .Track__header__process__item {
              &__box {
                background-color: $brand-color !important;
              }

              &__circle {
                background-color: #fff !important;
              }

              &__text {
                color: #fff !important;
              }
            }
          }
        }

        & svg.TrackArrow {
          &:nth-of-type(3) {
            display: none;
          }
        }
      }
    }

    &__body {
      &__card {
        &__button {
          & button {
            min-width: 100%;
          }
        }
      }
    }
  }
}
.iframe-track {
  margin: 14rem 0 3rem 0;
  height: 18rem !important;

  iframe{
    border:none;
    overflow:hidden;
    width: 100%;
    height: 100%;
  }
}